export const HuginnIcon = ({
  fill,
  stroke,
  className,
}: {
  fill?: string;
  stroke?: string;
  className?: string;
}) => {
  return (
    <svg
      className={`cursor-pointer ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      width="66.5"
      height="66.5"
      viewBox="0 0 66.5 66.5"
    >
      <path
        id="chatbot_13727399"
        d="M56.375,0H42.625A9.636,9.636,0,0,0,33,9.625V29.411A3.593,3.593,0,0,0,38.6,32.4L45.8,27.5H56.375A9.636,9.636,0,0,0,66,17.878V9.628A9.636,9.636,0,0,0,56.375,0ZM63.25,17.875a6.883,6.883,0,0,1-6.875,6.875h-11a1.376,1.376,0,0,0-.773.236l-7.54,5.123a.843.843,0,0,1-1.309-.7V9.625A6.883,6.883,0,0,1,42.628,2.75h13.75a6.883,6.883,0,0,1,6.875,6.875v8.25ZM50.875,41.25H49.5a10.945,10.945,0,0,0-3.143-7.7,1.375,1.375,0,1,0-1.966,1.922,8.211,8.211,0,0,1,2.357,5.775V55a8.26,8.26,0,0,1-8.25,8.25h-22A8.26,8.26,0,0,1,8.25,55V41.25A8.26,8.26,0,0,1,16.5,33h11a1.376,1.376,0,0,0,1.375-1.375V24.75a1.375,1.375,0,0,0-2.75,0v5.5H16.5a11.011,11.011,0,0,0-11,11H4.125A4.13,4.13,0,0,0,0,45.375v8.25A4.13,4.13,0,0,0,4.125,57.75H5.849A11.02,11.02,0,0,0,16.5,66h22a11.017,11.017,0,0,0,10.651-8.25h1.724A4.13,4.13,0,0,0,55,53.625v-8.25A4.13,4.13,0,0,0,50.875,41.25ZM4.125,55A1.376,1.376,0,0,1,2.75,53.625v-8.25A1.376,1.376,0,0,1,4.125,44H5.5V55ZM52.25,53.625A1.377,1.377,0,0,1,50.875,55H49.5V44h1.375a1.377,1.377,0,0,1,1.375,1.375ZM13.75,49.5a5.5,5.5,0,1,0,5.5-5.5A5.506,5.506,0,0,0,13.75,49.5Zm8.25,0a2.75,2.75,0,1,1-2.75-2.75A2.755,2.755,0,0,1,22,49.5ZM35.75,55a5.5,5.5,0,1,0-5.5-5.5A5.506,5.506,0,0,0,35.75,55Zm0-8.25A2.75,2.75,0,1,1,33,49.5,2.753,2.753,0,0,1,35.75,46.75Z"
        transform="translate(0.25 0.25)"
        fill={fill}
        stroke={stroke}
        strokeWidth="0.5"
      />
    </svg>
  );
};
