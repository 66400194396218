export const LoadingIcon = ({
  fill,
  className,
}: {
  className: string;
  fill?: string;
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="18.509"
      height="19.611"
      viewBox="0 0 18.509 19.611"
    >
      <g transform="translate(-1.245 -1.005)">
        <g transform="translate(1.245 1.005)">
          <path
            d="M11.887,1a2.735,2.735,0,1,0,2.73,2.739A2.755,2.755,0,0,0,11.887,1ZM5.569,3.578a2.312,2.312,0,1,0,1.694.671,2.315,2.315,0,0,0-1.694-.671ZM16.614,5.339a.549.549,0,1,0,.4.161.551.551,0,0,0-.4-.161ZM3.343,9.287a2.1,2.1,0,1,0,2.1,2.1A2.112,2.112,0,0,0,3.343,9.287Zm15.562,1.252a.849.849,0,1,0,.85.85A.853.853,0,0,0,18.905,10.538ZM5.626,15.05a1.84,1.84,0,1,0,1.306.537,1.844,1.844,0,0,0-1.306-.537ZM16.6,15.82a1.071,1.071,0,1,0,.785.311,1.073,1.073,0,0,0-.785-.311Zm-5.51,1.9a1.463,1.463,0,1,0,.038,0Z"
            transform="translate(-1.245 -1.005)"
            fill={fill}
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};
